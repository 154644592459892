@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@500;600&display=swap");

body {
  margin: 0;
  background: #121212;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

*::-webkit-scrollbar {
  width: 0px;
}

::selection {
  background: #FE4A49;
}

.white-glassmorphism {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(20px);
}

.nodec {
  text-decoration: none;
}

.icon {
  margin-left: 10px;
}

.backdrop {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(20px);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1000;
  align-items: center;
}

.modal {
  width: clamp(50%, 700px, 90%);
  height: 200px;
  padding: 0 2rem;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 960px) {
    height: 350px;
  }

}

.pointer {
  cursor: pointer;
}